
.graphiql-container {
  --color-primary: 320, 95%, 43%;
  --color-secondary: 242, 51%, 61%;
  --color-tertiary: 188, 100%, 36%;
  --color-info: 208, 100%, 46%;
  --color-success: 158, 60%, 42%;
  --color-warning: 36, 100%, 41%;
  --color-error: 13, 93%, 58%;
  --color-neutral: 219, 28%, 32%;
  --color-base: 219, 28%, 100%;
  --alpha-secondary: .76;
  --alpha-tertiary: .5;
  --alpha-background-heavy: .15;
  --alpha-background-medium: .1;
  --alpha-background-light: .07;
  --font-family: "Roboto", sans-serif;
  --font-family-mono: "Fira Code", monospace;
  --font-size-hint: .75rem;
  --font-size-inline-code: .8125rem;
  --font-size-body: .9375rem;
  --font-size-h4: 1.125rem;
  --font-size-h3: 1.375rem;
  --font-size-h2: 1.8125rem;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --line-height: 1.5;
  --px-2: 2px;
  --px-4: 4px;
  --px-6: 6px;
  --px-8: 8px;
  --px-10: 10px;
  --px-12: 12px;
  --px-16: 16px;
  --px-20: 20px;
  --px-24: 24px;
  --border-radius-2: 2px;
  --border-radius-4: 4px;
  --border-radius-8: 8px;
  --border-radius-12: 12px;
  --popover-box-shadow: 0px 6px 20px rgba(59, 76, 106, .13), 0px 1.34018px 4.46726px rgba(59, 76, 106, .0774939), 0px .399006px 1.33002px rgba(59, 76, 106, .0525061);
  --popover-border: none;
  --sidebar-width: 60px;
  --toolbar-width: 40px;
  --session-header-height: 51px;
}

.graphiql-doc-explorer-default-value {
  color: hsl(var(--color-success))
}

a.graphiql-doc-explorer-type-name {
  color: hsl(var(--color-warning));
  text-decoration: none
}

a.graphiql-doc-explorer-type-name:hover {
  text-decoration: underline
}

a.graphiql-doc-explorer-type-name:focus {
  outline: hsl(var(--color-warning)) auto 1px
}

.graphiql-doc-explorer-argument>*+* {
  margin-top: var(--px-12)
}

.graphiql-doc-explorer-argument-name {
  color: hsl(var(--color-secondary))
}

.graphiql-doc-explorer-argument-deprecation {
  background-color: hsla(var(--color-warning), var(--alpha-background-light));
  border: 1px solid hsl(var(--color-warning));
  border-radius: var(--border-radius-4);
  color: hsl(var(--color-warning));
  padding: var(--px-8)
}

.graphiql-doc-explorer-argument-deprecation-label {
  font-size: var(--font-size-hint);
  font-weight: var(--font-weight-medium)
}

.graphiql-doc-explorer-deprecation {
  background-color: hsla(var(--color-warning), var(--alpha-background-light));
  border: 1px solid hsl(var(--color-warning));
  border-radius: var(--px-4);
  color: hsl(var(--color-warning));
  padding: var(--px-8)
}

.graphiql-doc-explorer-deprecation-label {
  font-size: var(--font-size-hint);
  font-weight: var(--font-weight-medium)
}

.graphiql-doc-explorer-directive {
  color: hsl(var(--color-secondary))
}

.graphiql-doc-explorer-section-title {
  align-items: center;
  display: flex;
  font-size: var(--font-size-hint);
  font-weight: var(--font-weight-medium);
  line-height: 1
}

.graphiql-doc-explorer-section-title>svg {
  height: var(--px-16);
  margin-right: var(--px-8);
  width: var(--px-16)
}

.graphiql-doc-explorer-section-content {
  margin-left: var(--px-8);
  margin-top: var(--px-16)
}

.graphiql-doc-explorer-section-content>*+* {
  margin-top: var(--px-16)
}

.graphiql-doc-explorer-root-type {
  color: hsl(var(--color-info))
}

.graphiql-doc-explorer-search {
  color: hsla(var(--color-neutral), var(--alpha-secondary))
}

.graphiql-doc-explorer-search:not([data-state="idle"]) {
  border: var(--popover-border);
  border-radius: var(--border-radius-4);
  box-shadow: var(--popover-box-shadow);
  color: hsla(var(--color-neutral), 1)
}

.graphiql-doc-explorer-search:not([data-state="idle"]) .graphiql-doc-explorer-search-input {
  background: hsl(var(--color-base))
}

.graphiql-doc-explorer-search-input {
  align-items: center;
  background-color: hsla(var(--color-neutral), var(--alpha-background-light));
  border-radius: var(--border-radius-4);
  display: flex;
  padding: var(--px-8) var(--px-12)
}

.graphiql-doc-explorer-search [role=combobox] {
  border: none;
  background-color: transparent;
  margin-left: var(--px-4);
  width: 100%
}

.graphiql-doc-explorer-search [role=combobox]:focus {
  outline: none
}

.graphiql-doc-explorer-search [role=listbox] {
  background-color: hsl(var(--color-base));
  border: none;
  border-bottom-left-radius: var(--border-radius-4);
  border-bottom-right-radius: var(--border-radius-4);
  border-top: 1px solid hsla(var(--color-neutral), var(--alpha-background-heavy));
  max-height: 400px;
  overflow-y: auto;
  margin: 0;
  font-size: var(--font-size-body);
  padding: var(--px-4);
  position: relative
}

.graphiql-doc-explorer-search [role=option] {
  border-radius: var(--border-radius-4);
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  overflow-x: hidden;
  padding: var(--px-8) var(--px-12);
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer
}

.graphiql-doc-explorer-search [role=option][data-headlessui-state=active] {
  background-color: hsla(var(--color-neutral), var(--alpha-background-light))
}

.graphiql-doc-explorer-search [role=option]:hover {
  background-color: hsla(var(--color-neutral), var(--alpha-background-medium))
}

.graphiql-doc-explorer-search [role=option][data-headlessui-state=active]:hover {
  background-color: hsla(var(--color-neutral), var(--alpha-background-heavy))
}

:is(.graphiql-doc-explorer-search [role="option"])+:is(.graphiql-doc-explorer-search [role="option"]) {
  margin-top: var(--px-4)
}

.graphiql-doc-explorer-search-type {
  color: hsl(var(--color-info))
}

.graphiql-doc-explorer-search-field {
  color: hsl(var(--color-warning))
}

.graphiql-doc-explorer-search-argument {
  color: hsl(var(--color-secondary))
}

.graphiql-doc-explorer-search-divider {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  font-size: var(--font-size-hint);
  font-weight: var(--font-weight-medium);
  margin-top: var(--px-8);
  padding: var(--px-8) var(--px-12)
}

.graphiql-doc-explorer-search-empty {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  padding: var(--px-8) var(--px-12)
}

a.graphiql-doc-explorer-field-name {
  color: hsl(var(--color-info));
  text-decoration: none
}

a.graphiql-doc-explorer-field-name:hover {
  text-decoration: underline
}

a.graphiql-doc-explorer-field-name:focus {
  outline: hsl(var(--color-info)) auto 1px
}

.graphiql-doc-explorer-item>:not(:first-child) {
  margin-top: var(--px-12)
}

.graphiql-doc-explorer-argument-multiple {
  margin-left: var(--px-8)
}

.graphiql-doc-explorer-enum-value {
  color: hsl(var(--color-info))
}

.graphiql-doc-explorer-header {
  display: flex;
  justify-content: space-between;
  position: relative
}

.graphiql-doc-explorer-header:focus-within .graphiql-doc-explorer-title {
  visibility: hidden
}

.graphiql-doc-explorer-header:focus-within .graphiql-doc-explorer-back:not(:focus) {
  color: transparent
}

.graphiql-doc-explorer-header-content {
  display: flex;
  flex-direction: column;
  min-width: 0
}

.graphiql-doc-explorer-search {
  position: absolute;
  right: 0;
  top: 0
}

.graphiql-doc-explorer-search:focus-within {
  left: 0
}

.graphiql-doc-explorer-search [role=combobox] {
  height: 24px;
  width: 4ch
}

.graphiql-doc-explorer-search [role=combobox]:focus {
  width: 100%
}

a.graphiql-doc-explorer-back {
  align-items: center;
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  display: flex;
  text-decoration: none
}

a.graphiql-doc-explorer-back:hover {
  text-decoration: underline
}

a.graphiql-doc-explorer-back:focus {
  outline: hsla(var(--color-neutral), var(--alpha-secondary)) auto 1px
}

a.graphiql-doc-explorer-back:focus+.graphiql-doc-explorer-title {
  visibility: unset
}

a.graphiql-doc-explorer-back>svg {
  height: var(--px-8);
  margin-right: var(--px-8);
  width: var(--px-8)
}

.graphiql-doc-explorer-title {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-h2);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.graphiql-doc-explorer-title:not(:first-child) {
  font-size: var(--font-size-h3);
  margin-top: var(--px-8)
}

.graphiql-doc-explorer-content>* {
  color: hsla(var(--color-neutral), var(--alpha-secondary));
  margin-top: var(--px-20)
}

.graphiql-doc-explorer-error {
  background-color: hsla(var(--color-error), var(--alpha-background-heavy));
  border: 1px solid hsl(var(--color-error));
  border-radius: var(--border-radius-8);
  color: hsl(var(--color-error));
  padding: var(--px-8) var(--px-12)
}

