.content h1 {
    font-weight: 300;
    font-size: 34px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin: 0;
}

.content h2 {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 0;
    margin: 0;
}

.content h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.167;
    letter-spacing: 0;
    margin: 0;
}

.content h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.235;
    letter-spacing: 0;
    margin: 0;
}