.CodeMirror {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.cm-keyword {
  /* color: #577217 !important */
}

.cm-atom {
  color: #13294B !important
}
