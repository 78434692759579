.rdg-editor-container {
    padding : 0px;
    background-color: white;
}

.dataGridFilter {
    line-height: 35px;
    padding: 0;
}

.dataGridFilter > div {
    padding-block: 0;
    padding-inline: 8px;
}

.dataGridFilter > div :first-child {
}
