.jsoneditor-react-container {
  width: 100%;
  height: 100%;
}

div.jsoneditor {
  border:thin solid #EEEEEE;
  border-radius: 4px;
}

div.jsoneditor-menu {
  background-color: #EEEEEE;
  border-bottom: #EEEEEE;
  color: #13294B;
}

div.jsoneditor .jsoneditor-statusbar {
  border: unset;
  background-color: #EEEEEE;
}
div.jsoneditor .ace-jsoneditor .ace_gutter {
  background-color: #EEEEEE;
}
div.jsoneditor .ace-jsoneditor .ace_marker-layer .ace_active-line {
  background: rgba(207,69,32, .1)
}
div.jsoneditor .ace-jsoneditor .ace_gutter-active-line {
  background-color : rgba(207,69,32, .1)
}

div.jsoneditor .jsoneditor-menu > button {
  opacity: unset;
}

div.jsoneditor .jsoneditor-menu > button.jsoneditor-format {
  background: url("../images/icons/format.svg");
  background-color: white;
  border-radius: 4px;
}
div.jsoneditor .jsoneditor-menu > button.jsoneditor-compact {
  background: url("../images/icons/compact.svg");
  background-color: white;
  border-radius: 4px;
}

div.jsoneditor .jsoneditor-menu > button.jsoneditor-expand-all {
  background: url("../images/icons/expand.svg");
  background-color: white;
  border-radius: 4px;

}

div.jsoneditor .jsoneditor-menu > button.jsoneditor-collapse-all {
  background: url("../images/icons/collapse.svg");
  background-color: white;
  border-radius: 4px;

}

div.jsoneditor .jsoneditor-menu > button.jsoneditor-transform {
  display: none;
}

div.jsoneditor .jsoneditor-menu > .jsoneditor-modes > button.jsoneditor-separator {
  background-color: white;
  color: #13294B;
  border-radius: 4px;
}

.jsoneditor-contextmenu .jsoneditor-menu li ul li button {
  color: #13294B;
}

.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected,
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:hover,
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:focus {
  color: #CF4520;
  background-color: rgba(207,69,32, .1);
}

.jsoneditor-contextmenu .jsoneditor-menu li ul li button:hover,
.jsoneditor-contextmenu .jsoneditor-menu li ul li button:focus {
  background-color: #f5f5f5;
}

div.jsoneditor-menu > button.jsoneditor-repair {
  display: none;
}

div.jsoneditor-menu a.jsoneditor-poweredBy {
  display: none;
}

div.jsoneditor-tree button.jsoneditor-dragarea {
  display: none;
}