.editableComponent {
}

.adminSiteEditButton {
    position : absolute !important;
    z-index : 1 !important;
    margin-top : -16px ;
    visibility : hidden !important;
}

.editableComponent:hover .adminSiteEditButton {
    visibility: visible !important;
}

.editableComponentOutline:hover {
    outline: 2px dashed deepskyblue !important;
    outline-offset: -2px !important;
}

.editableComponentOutlineOut2:hover {
    outline: 2px dashed deepskyblue !important;
    outline-offset: 2px !important;
}

.editableComponentOutlineIn4:hover {
    outline: 2px dashed deepskyblue !important;
    outline-offset: -4px !important;
}

.editableComponentOutlineIn16:hover {
    outline: 2px dashed deepskyblue !important;
    outline-offset: -16px !important;
}

.editableComponentHighlight {
    outline: 3px dashed orangered !important;
}

.editableComponentHighlight:hover {
    outline: 3px dashed orangered !important;
}

.editableComponentHighlightOffsetIn3 {
    outline-offset: -3px !important;
}

.editableComponentHighlightOffsetIn16 {
    outline-offset: -16px !important;
}
.editableComponentHighlightOffsetIn16:hover {
    outline: 3px dashed orangered !important;
}